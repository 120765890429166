import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class HelmetMeta extends Component {
    render() {
        // DO NOT DECONSTRUCT THE PROPS, JUST DONT !!!
        // default value for metatags
        // const img = './previewImage.png'
        // const title = this.props.title
        //     ? this.props.title
        //     : 'Pranav Goel\'s Personal Website';
        // const description = this.props.description
        //     ? this.props.description
        //     : 'Learn About Pranav Goel';
        // const site_name = this.props.site_name
        //     ? this.props.site_name
        //     : 'Pranav Goel - Engineer, Student, Worker';
        // const keywords = this.props.keywords
        //     ? this.props.keywords
        //     : 'Pranav Goel, Electrical Engineer, UIUC';
        // const image = this.props.image ? this.props.image : require(`${img}`)

        // const url = this.props.url ? this.props.url : '/';
        // const verify = this.props.verify ? this.props.verify : '';
        // const verifyname = this.props.verifyname ? this.props.verifyname : '';

        return (
            <Helmet>
                {/* <title>{title}</title>
                <meta name='description' property='og:description' content={description} />
                <meta name='keywords' content={keywords} />
                <meta name='title' property='og:title' content={title}></meta>
                <meta name='site_name' property='og:site_name' content={site_name} />
                <meta name='image' property='og:image' content={image}/>
                <meta name='url' property='og:url' content={url} />
                <meta name='type' property='og:type' content='website' />
                <meta name={verifyname} content={verify} /> */}

                {/* open graph meta tags */}
                {/* <meta property='og:site_name' content={site_name} /> */}
                {/* <meta property='og:title' content={title} /> */}
                {/* <meta property='og:description' content={description} /> */}
                {/* <meta property='og:url' content={url} />
                <meta property='og:type' content='website' /> */}
                {/* <meta property='og:image' content={image}/> */}

                {/* Twitter cards tags */}
                {/* <meta name='twitter:card' content='summary_large_image' />
                <meta name='twitter:title' content={title} />
                <meta name='twitter:description' content={description} /> */}
            </Helmet>
        );
    }
}

export default HelmetMeta;

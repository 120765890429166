import React, { Component } from 'react';
import { connect } from 'react-redux';
import './index.scss';
// import 'react-typed/dist/animatedCursor.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import 'aos/dist/aos.js'; // You can also use <link> for styles
// import Col from 'react-bootstrap';
// import Row from 'react-bootstrap';

class WorkExperience extends Component {
    render() {
        const { title, companies } = this.props.data;
        AOS.init();
        // <>{content.map((section, i) => this.handleContent(section, i))}</>
        return (
            <>
                <section
                    className='workExperience module-gray module'
                    title={title}
                    id={title}
                    data-aos='fade-up'
                    data-aos-offset='-200'
                    data-aos-delay='50'
                    data-aos-duration='1000'
                    data-aos-easing='ease-in-out'
                    data-aos-mirror='true'
                    data-aos-once='false'
                    data-aos-anchor-placement='top-center'>
                    <div className='container-skills'>
                        <Container>
                            <div className='module-header'>
                                <h2 className='module-title'>
                                    Work Experience <span>Experience</span>
                                </h2>
                                <div className='module-line'></div>
                            </div>
                            <Row>
                                {companies.map((company, i) => (
                                    <div
                                        className='companyBox'
                                        data-aos='fade-up'
                                        data-aos-offset='-200'
                                        data-aos-delay='50'
                                        data-aos-duration='1000'
                                        data-aos-easing='ease-in-out'
                                        data-aos-mirror='true'
                                        data-aos-once='false'
                                        data-aos-anchor-placement='top-center'
                                        key={i}>
                                        {/* <Col
                                            sm='4'
                                            className='company'
                                            id='comp'> */}
                                            <Row className='overrideRow headPadding'>
                                                <h2 className='headPadding'>{company.name}</h2>
                                            </Row>
                                            <Row className='overrideRow rowQualifiers'>
                                                <h3>{company.position}</h3>
                                            </Row>
                                            <Row className='overrideRow'>
                                                <h4>{company.date}</h4>
                                            </Row>
                                            <Row className='overrideRow'>
                                                <h6>{company.location}</h6>
                                            </Row>
                                            <Row
                                                className='overrideRow'>
                                                <div className="aboutPadding">
                                                    <h5 className="">{company.about}</h5>
                                                </div>
                                            </Row>
                                        {/* </Col> */}
                                        <Row xs='12' className='work closer'>
                                            <div className='contentFormat'>
                                                <p>{company.info}</p>
                                            </div>
                                        </Row>
                                    </div>
                                ))}
                            </Row>
                        </Container>
                    </div>
                    {/* <div id="particles-js"></div> */}
                    {/* <canvas id='canvas1' ref={this.canvasRef}></canvas> */}
                </section>
            </>
        );
    }
}

const mapStateToProps = (state, passed) => {
    return {
        data: state.content.workExperience[passed.id]
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkExperience);

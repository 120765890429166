// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600|Source+Code+Pro:300|Titillium+Web:100,200,300,400,500,600|Roboto+Mono:300,300i,400,400i);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  overflow-x: hidden !important; }

.App {
  text-align: center; }

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: #61dafb; }

/* change the container width with media query */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }
`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAEA;EACI,6BAA6B,EAAA;;AAEjC;EACI,kBAAkB,EAAA;;AAGtB;EACI,4CAA4C;EAC5C,cAAc;EACd,oBAAoB,EAAA;;AAGxB;EACI,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY,EAAA;;AAGhB;EACI,cAAc,EAAA;;AAGlB,gDAAA;AAQA;EACI;IACI,uBAAuB,EAAA;EAE3B;IACI,yBAAyB,EAAA,EAAA","sourcesContent":["@import url('https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600|Source+Code+Pro:300|Titillium+Web:100,200,300,400,500,600|Roboto+Mono:300,300i,400,400i');\n\nbody {\n    overflow-x: hidden !important;\n}\n.App {\n    text-align: center;\n}\n\n.App-logo {\n    animation: App-logo-spin infinite 20s linear;\n    height: 40vmin;\n    pointer-events: none;\n}\n\n.App-header {\n    background-color: #282c34;\n    min-height: 100vh;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    font-size: calc(10px + 2vmin);\n    color: white;\n}\n\n.App-link {\n    color: #61dafb;\n}\n\n/* change the container width with media query */\n// .homepage-container {\n//   width: 1200px;\n//   margin-top: 0;\n//   margin-left: auto;\n//   margin-right: auto;\n// }\n\n@keyframes App-logo-spin {\n    from {\n        transform: rotate(0deg);\n    }\n    to {\n        transform: rotate(360deg);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import mapObjectToComponent from '../../../utils/mapComponent';
import HelmetMeta from '../../../store/components/helmetMeta';
import './page.scss';

class DynamicPage extends Component {
    renderMeta = () => {
        const pageObject = this.props.data;
        // DO NOT DECONSTRUCT THE PROPS, because there is a default value for each object defined in HelmetMeta !!! ///
        if (pageObject.metaTags) {
            return (
                <HelmetMeta
                    title={pageObject.metaTags.title}
                    description={pageObject.metaTags.description}
                    img={pageObject.metaTags.img}
                    site_name={pageObject.metaTags.site_name}
                    keywords={pageObject.metaTags.keywords}
                    url={pageObject.url}
                    verifyname={pageObject.metaTags.verifyname}
                    verify={pageObject.metaTags.verify}
                />
            );
        } else {
            return <></>;
        }
    };

    render() {
        return (
            <div className='dynamic-page'>
                {this.renderMeta()}
                {this.props.data.instances.map(mapObjectToComponent)}
            </div>
        );
    }
}

const mapStateToProps = (state, passed) => {
    return {
        data: state.content.dynamicPage[passed.id],
        defaultMetaTags: state.content.dynamicPage.root.metaTags
    };
};

export default connect(mapStateToProps)(DynamicPage);

import 'babel-polyfill';
import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from './utils/scrollToTop';
import App from './App';
import { store } from './store';

import './index.css';
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
        <Provider store={store}>
            <Router>
                <ScrollToTop>
                    <App />
                </ScrollToTop>
            </Router>
        </Provider>
    );

import React, { Component } from 'react';
import { connect } from 'react-redux';
import './index.scss';
// import 'react-typed/dist/animatedCursor.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import 'aos/dist/aos.js'; // You can also use <link> for styles
import AnimateHeight from 'react-animate-height';
// import Col from 'react-bootstrap';
// import Row from 'react-bootstrap';

class Clubs extends Component {
    state = {
        height: 0
    };

    toggle = () => {
        const { height } = this.state;

        this.setState({
            height: height === 0 ? 'auto' : 0
        });
    };
    render() {
        const { title, clubs } = this.props.data;
        const { height } = this.state;
        AOS.init();
        // <>{content.map((section, i) => this.handleContent(section, i))}</>
        return (
            <>
                <section
                    className='clubs module'
                    title={title}
                    id={title}
                    data-aos='fade-up'
                    data-aos-offset='-200'
                    data-aos-delay='50'
                    data-aos-duration='1000'
                    data-aos-easing='ease-in-out'
                    data-aos-mirror='true'
                    data-aos-once='false'
                    data-aos-anchor-placement='top-center'>
                    <div className='container-skills'>
                        <Container>
                            <div className='module-header'>
                                <h2 className='module-title'>
                                    Pranav's Clubs <span>Extra-Curricular</span>
                                </h2>
                                <div className='module-line'></div>
                            </div>
                            <div
                                className='animate-height'
                                onClick={this.toggle}>
                                <h3>
                                    {height === 0 ? 'Show' : 'Hide'} Club
                                    Descriptions
                                </h3>
                                <FontAwesomeIcon
                                    icon={height === 0 ? 'plus' : 'minus'}>
                                    {height === 0 ? 'Open' : 'Close'}
                                </FontAwesomeIcon>
                            </div>
                            <Row>
                                {clubs.map((club, i) => (
                                    <Col
                                        sm='4'
                                        className='clubs'
                                        data-aos='fade-up'
                                        data-aos-offset='-200'
                                        data-aos-delay='50'
                                        data-aos-duration='1000'
                                        data-aos-easing='ease-in-out'
                                        data-aos-mirror='true'
                                        data-aos-once='false'
                                        data-aos-anchor-placement='top-center'
                                        key={i}>
                                        <div className='iconbox'>
                                            <div className='iconbox-text'>
                                                <h3 className='iconbox-title'>
                                                    <a
                                                        className='linkClub'
                                                        href={club.website}
                                                        target='_blank'
                                                        rel='noopener noreferrer'>
                                                        {club.title}
                                                    </a>
                                                </h3>
                                            </div>

                                            <AnimateHeight
                                                duration={500}
                                                height={height} // see props documentation below
                                            >
                                                <div className='iconbox-desc'>
                                                    {club.desc}
                                                </div>
                                            </AnimateHeight>
                                        </div>
                                    </Col>
                                ))}
                                <br className='clearIt' />
                            </Row>
                        </Container>
                    </div>
                    {/* <div id="particles-js"></div> */}
                    {/* <canvas id='canvas1' ref={this.canvasRef}></canvas> */}
                </section>
            </>
        );
    }
}

const mapStateToProps = (state, passed) => {
    return {
        data: state.content.clubs[passed.id]
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Clubs);

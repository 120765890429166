// import { isAbsolute } from 'path';

var initialState = {
    dynamicPage: {
        root: {
            instances: [
                { component: 'navbar', id: 'root' },
                { component: 'home', id: 'root' },
                { component: 'aboutMe', id: 'root' },
                { component: 'workExperience', id: 'root' },
                { component: 'clubs', id: 'root' },
                { component: 'skills', id: 'root' },
                { component: 'software', id: 'root' },
                { component: 'contactMe', id: 'root' }
            ],
            url: '/',
            metaTags: {}
        }
    },
    navbar: {
        root: {
            heading: 'Pranav Goel',
            // img: '../components/navbar/images/previewImage.png',
            nav: [
                { title: 'Home' },
                { title: 'About Me' },
                { title: 'Skills' },
                { title: 'Software' },
                { title: 'Work Experience' },
                { title: 'Clubs' },
                { title: 'My Blog' },
                // { title: 'Instagram Feed' },
                { title: 'Contact Me' }
            ]
        }
    },
    aboutMe: {
        root: {
            title: 'About Me',
            img: './images/pranavgradcrop.jpg',
            titlebk: 'About',
            info1: {
                title: 'Electrical Engineering',
                description:
                    'I graduated with a bachelors in Electrical Engineering from the University of Illinois at Urbana-Champaign. I am currently working at General Motors as a hardware engineer! I have been super interested in the future of EV, autonomous vehicles, and technology.'
            },
            info2: {
                title: 'Hobbies',
                description:
                    'I have a passion for landscape and portrait photography. I currently use a Fujifilm XT3 and its an absolute joy to use. From a young age I have loved to travel and now that I own a camera, I can share that experience with everyone! I also love making music - I play guitar and piano. I played in a one show college band called Paper Trails with a couple of friends. We only played one but it was an awesome experience. I like getting out and about with athletics like tennis and golf.'
            }
        }
    },
    skills: {
        root: {
            title: 'Skills',
            skills: [
                {
                    icon: 'language',
                    title: 'Languages',
                    desc:
                        'I am a native English speaker, proficient German speaker, and basic Hindi speaker (with high comprehension).'
                },
                {
                    icon: 'memory',
                    title: 'PCB and Hardware Design',
                    desc:
                        'As an Electrical Engineering major, I am constantly working on projects that require PCB Design. As such, I have experience with programs such as Kicad and Eagle. I also have experience with developing hardware through FPGAs. I built a tron game implemented almost fully on hardware using Quartus and an Intel FPGA.'
                },
                {
                    icon: 'project-diagram',
                    title: 'Control Systems',
                    desc:
                        'As a member of Student Space Systems, I have gained a lot of experience working with control systems. After working as an engineer for the engine controller team my freshman year, I became the Engine Controller Project Manager. While working with the engine controller team, I have had to work with several aspects of controllers one such being PID.'
                },
                {
                    icon: 'shield-alt',
                    title: 'Cybersecurity',
                    desc:
                        'I started taking interest in Cyber Security during High School. I participated in several Capture the Flag events (CTFs) and am currently a research assistant in SIGPwny. SIGpwny is a cyber security special interest group at UIUC. I particularly am interested in Forensics (incident response), Steganography, and Cryptography.'
                },
                {
                    icon: 'desktop',
                    title: 'Web Design',
                    desc:
                        'I have a lot of experience in frontend development and web design (particularly translating design to elements on the web). While I worked at Checkbook, I redesigned their navbar and implemented several new elements on their website.'
                },
                {
                    icon: 'gears',
                    title: 'Integration and Testing for Autonomous Vehicles',
                    desc:
                        'At General Motors, I have experience integrating autonomous vehicle hardware and software into the Cruise Origin and Bolt vehicles. I have worked with INCA, VSpy, C/C++, and python in HiL and SiL.'
                }
            ]
        }
    },
    software: {
        root: {
            title: 'Software',
            programs: [
                { language: 'c', name: 'C', width: '75' },
                { language: 'c++', name: 'C++', width: '75' },
                // { language: 'csharp', name: 'C#', width: '75' },
                { language: 'systemverilog', name: 'systemverilog', width: '75' },
                { language: 'java', name: 'Java', width: '75' },
                { language: 'javascript', name: 'Javascript', width: '75' },
                { language: 'react', name: 'React', width: '75' },
                { language: 'puppeteer', name: 'Puppeteer', width: '50' },
                { language: 'python', name: 'Python', width: '75' },
                { language: 'linux', name: 'Linux + Bash', width: '75' }
            ]
        }
    },
    workExperience: {
        root: {
            title: 'Work Experience',
            companies: [
                {
                    name: 'General Motors',
                    position: 'Software Engineer in Autonomous',
                    date: 'June 2023 - Present',
                    location: 'Milford, Michigan',
                    about: 'Fortune 25 automotive OEM. Largest automaker in the United States.',
                    info: 'Diagnose and verify embedded software in Hardware in the Loop (HiL) and in L4 Autonomous Vehicles (Cruise Origin) to achieve saleable status. Implement cybersecurity features - Device Registration, Session Registration, and Key Exchange. Improve code robustness and stability to reach rigorous industry safety standards. Calibrate Advanced Driving Integration Module (ADIM) using INCA (ETAS) and VSpy (Intrepid Control Systems). Support cross-functional teams for system wide controller integration post development releases.'
                },
                {
                    name: 'General Motors',
                    position: 'Active Chassis Calibration Engineer',
                    date: 'Feb 2023 - June 2023',
                    location: 'Milford, Michigan',
                    about: 'Fortune 25 automotive OEM. Largest automaker in the United States.',
                    info: 'Developed Data Analysis Software via Matlab and Python for Brake-by-Wire. Conducted Design of Experiment Testing in Software in the Loop (SiL) via CarSim.'
                },
                {
                    name: 'General Motors',
                    position: 'Autonomous Vehicle Integration Engineer',
                    date: 'July 2022 - February 2023',
                    location: 'Milford, Michigan',
                    about: 'Fortune 25 automotive OEM. Largest automaker in the United States.',
                    info: 'Integrated and updated software for Autonomous Driving Super Computer (ADSC), lidar and radar sensors, and cameras. Collaborated with Cruise team to integrate new ROS features for L4 Autonomous Vehicle. Resolved issues relating to ADSC integration with ADIM and the rest of the automotive architecture'
                },
                {
                    name: 'Raytheon Technologies',
                    position: 'Software Engineering Co-op',
                    date: 'Spring and Summer 2021',
                    location: 'Cedar Rapids, Iowa',
                    about: 'Fortune 100 defense company pioneering technology for space, aeronautics, defense and cyberspace',
                    info:
                        "I developed, tested and integrated API for radios used by control towers to communicate with airplanes. I improved the Board Support Package (BSP) automation for faster builds and higher abstraction. I also collaborated to modernize software architecture for the F22 airplane using a modular approach"
                },
                {
                    name: 'Northrop Grumman',
                    position: 'Hardware Engineering Intern',
                    date: 'Summer 2020',
                    location: 'Rolling Meadows, Illinois',
                    about: 'Fortune 500 defense company pioneering technology for space, aeronautics, defense and cyberspace',
                    info:
                        "I implemented AGILE solution by setting up JIRA for the hardware team and interfacing with departments. I streamlined workflow for managers by constructing an automated organization tool via Javascript. I also analyzed drafting drawings and approved new part requests in collaboration with component engineers"
                },
                {
                    name: 'Checkbook',
                    position: 'Software Engineering Intern',
                    date: 'Summer 2019',
                    location: 'Foster City, California',
                    about: 'A Silicon Valley Startup in the Fintech Industry',
                    info:
                        "I built Checkbook's SEO from ground up by integrating sales & marketing analytics with automated data processing, boosting their Alexa ranking by 45% Globally and 57% in the US resulting in increased web traffic and visitor engagement by 20%. While working there, I used NLP, Puppeteer, and various programming languages to build ubiquitous in-house solutions for content optimization and analytics for targeted marketing"
                
                }        
                // },
                // // {
                //     name: 'Utopia Global',
                //     position: 'Engineering Intern',
                //     date: 'Summer 2016',
                //     location: 'Mundelein, Illinois',
                //     about:
                //         'leading software and services company for end-to-end data quality, data migration, and data governance solutions',
                //     info:
                //         'I developed web-based customer relationship management (CRM) applications for the sales and marketing team using Joomla & HTML leveraging Webinar & Survey Data Analysis'
                // },
                // {
                //     name: 'Johnson Controls Automotive GmbH',
                //     position: 'R&D Engineering Intern',
                //     date: 'Spring 2015',
                //     location: 'Hannover, Germany',
                //     // about: 'leading software and services company for end-to-end data quality, data migration, and data governance solutions',
                //     info:
                //         'I performed Statistical Data Analysis using Minitab and MATLAB in Li-ion battery research. It was really great experience and was where I first began to learn about the importance of interdiscplinary communication and cooperation.'
                // }
            ]
        }
    },
    clubs: {
        root: {
            title: 'Clubs',
            clubs: [
                {
                    title: 'Student Space Systems/THRUST',
                    desc:
                        'THRUST is the premier student high-powered rocketry organization on the University of Illinois at Urbana-Champaign campus. I was an elected member of the executive board as the internal director. As a director I was responsible for making the club run cohesively, handle major communication channels, and manage all club events. While I was the engine controller project manager, I led my team to develop a most viable product that can be integrated with our Rocket.',
                    website: ''
                },
                {
                    title: 'SIGPwny',
                    desc:
                        'SIGPwny is a student-run interest group at the University of Illinois at Urbana-Champaign focused on information security. I joined as a freshman and have been working as a research assistant for the group. I have developed CTF problem sets, researched further into Forensics (incident response), Steganography, and Cryptography. I help run weekly meetings where we teach students of all levels about Cybersecurity.',
                    website: 'https://sigpwny.github.io/'
                },
                {
                    title: 'Engineering Council',
                    desc:
                        'Engineering Council is a union of engineering societies and committees dedicated to enriching the student collegiate experience through a distinctive array of programs and services. I was selected to join the Corporate Committee for my junior year! The corporate committee seeks to build relationships with corporate sponsors that will financially support the Council.',
                    website: 'https://www.site.ec.illinois.edu/'
                },
                {
                    title: 'Indian Student Association',
                    desc:
                        "The Indian Student Association (ISA) is UIUC's chapter. ISA aims to increase South Asian cultural awareness, promote service and serve as a social tie for the campus community.",
                    website: 'https://www.facebook.com/isaillinois/'
                }
            ]
        }
    },
    // blogPosts: {
    //     root: {
    //         title: 'My Blog',
    //         blogs: [
    //             {
    //                 title: 'Interning at a Silicon Valley Startup',
    //                 desc:
    //                     'Being exposed to the Fintech Industry was a unique experience that I will never forget. Read more about my experiences in this article...',
    //                 img: {
    //                     src:
    //                         './images/silicon-valley/camera-coffee-composition-400.jpg',
    //                     alt: 'Silicon Valley'
    //                 },
    //                 tag: 'Work',
    //                 link:
    //                     'http://afreshinquiry.web.illinois.edu/interning-at-a-silicon-valley-startup'
    //             },
    //             {
    //                 title: '5 Tips for Eating in College',
    //                 desc:
    //                     'Eating in college can be a challenge but with some effort you can stay healthy(ier)! Check out these 5 tips...',
    //                 img: {
    //                     src: './images/college-eating/food.jpg',
    //                     alt: 'Silicon Valley'
    //                 },
    //                 tag: 'College',
    //                 link: 'http://afreshinquiry.web.illinois.edu/5-tips-for-eating-in-college'
    //             }
    //             // {
    //             //     title: 'Test',
    //             //     desc:
    //             //         "Testing if this works lalalalala I can't believe I've done this HHAHAHHAH",
    //             //     img: {
    //             //         src:
    //             //             './images/silicon-valley/camera-coffee-composition-400.jpg',
    //             //         alt: 'Silicon Valley'
    //             //     },
    //             //     tag: 'Work',
    //             //     link: 'http://afreshinquiry.web.illinois.edu/'
    //             // }
    //         ]
    //     }
    // },
    contactMe: {
        root: {
            title: 'Contact Me',
            social: [
                // {
                //     id: 'facebook',
                //     link: '#contact-me',
                //     icon: ['fab', 'facebook-square']
                // },
                {
                    id: 'instagram',
                    link: 'https://www.instagram.com/etalerni/',
                    icon: ['fab', 'instagram']
                },
                {
                    id: 'linkedin',
                    link: 'https://www.linkedin.com/in/pranav-s-goel/',
                    icon: ['fab', 'linkedin']
                },
                {
                    id: 'github',
                    link: 'https://github.com/goelp14',
                    icon: ['fab', 'github-square']
                },
            ]
        }
    },
    home: {
        root: {
            title: 'Home',
            typedString: [
                'I am an ENGINEER',
                'I am a PROBLEM SOLVER',
                'I am CREATIVE'
            ],
            next: 'About Me'
        }
    }
};

function setPostName(state, action) {
    let newSelectedTitle = action.payload.title;
    let type = action.payload.type;
    // console.log("changing state to" + newSelectedTitle);

    return {
        ...state,
        posts: {
            ...state.posts,
            selectedTitle: newSelectedTitle,
            type: type
        }
    };
}

function content(state = initialState, action) {
    switch (action.type) {
        case 'SET_POST_TITLE':
            return setPostName(state, action);
        default:
            return state;
    }
}

export default content;

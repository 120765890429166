import React, { Component } from 'react';
import { connect } from 'react-redux';
import './index.scss';
// import 'react-typed/dist/animatedCursor.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import 'aos/dist/aos.js'; // You can also use <link> for styles
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
// import Col from 'react-bootstrap';
// import Row from 'react-bootstrap';

class ContactMe extends Component {
    render() {
        const { title, social } = this.props.data;
        AOS.init();
        // <>{content.map((section, i) => this.handleContent(section, i))}</>
        return (
            <>
                <section
                    className='contactMe module-gray module'
                    title={title}
                    id={title}
                    data-aos='fade-up'
                    data-aos-offset='-200'
                    data-aos-delay='50'
                    data-aos-duration='0'
                    data-aos-easing='ease-in-out'
                    data-aos-mirror='true'
                    data-aos-once='false'
                    data-aos-anchor-placement='top-center'>
                    <div className='container-contactMe'>
                        <div className='module-header'>
                            <h2 className='module-title'>
                                Contact Me <span>Contact</span>
                            </h2>
                            <div className='module-line'></div>
                        </div>
                        <div className='container-contact'>
                            {/* <div className='background' id='overlay'></div> */}
                            {/* <img
                                className='background'
                                src={require(`./images/circuit-board.jpg`)}
                                alt='circuit'></img> */}

                            <div className='contact-icons'>
                                <Container>
                                    {/* <Row>
                                        <h2>You Can Find Me:</h2>
                                    </Row> */}
                                    <Row>
                                        {social.map((column, i) => (
                                            <Col sm='3' key={i}>
                                                <a
                                                    href={column.link}
                                                    className='nohighlight'
                                                    target="_blank"
                                                    rel="noopener noreferrer">
                                                    <FontAwesomeIcon
                                                        id={column.id}
                                                        icon={
                                                            column.icon
                                                        }></FontAwesomeIcon>
                                                </a>
                                            </Col>
                                        ))}
                                        <Col sm='3'>
                                            <a
                                                href='mailto:pranavgoel.engineering@gmail.com'
                                                className='nohighlight'>
                                                <FontAwesomeIcon
                                                    id='email'
                                                    icon={faEnvelope}></FontAwesomeIcon>
                                            </a>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                            {/* <div className='wrap-contact'>
                                <form className='contact-form validate-form'>
                                    <div
                                        className='wrap-input validate-input'
                                        data-validate='Name is required'>
                                        <span className='label-input'>
                                            Name
                                        </span>
                                        <input
                                            className='input'
                                            type='text'
                                            name='name'
                                            placeholder='Name...'
                                        />
                                        <span className='focus-input' />
                                    </div>
                                    <div
                                        className='wrap-input validate-input'
                                        data-validate='Valid email is required: ex@abc.xyz'>
                                        <span className='label-input'>
                                            Email
                                        </span>
                                        <input
                                            className='input'
                                            type='text'
                                            name='email'
                                            placeholder='someone@emailaddress.com'
                                        />
                                        <span className='focus-input'></span>
                                    </div>

                                    <div className='wrap-input'>
                                        <span className='label-input input-phone'>
                                            Phone
                                        </span>
                                        <input
                                            className='input phone'
                                            type='text'
                                            name='phone'
                                            placeholder='(___) ___-___'
                                        />
                                        <span className='focus-input'></span>
                                    </div>

                                    <div
                                        className='wrap-input validate-input'
                                        data-validate='Message is required'>
                                        <span className='label-input'>
                                            Message
                                        </span>
                                        <textarea
                                            className='input'
                                            name='message'
                                            placeholder='Questions/Comments...'></textarea>
                                        <span className='focus-input'></span>
                                    </div>
                                    <div className='container-contact-form-btn'>
                                        <div className='wrap-contact-form-btn'>
                                            <div className='contact-form-bgbtn'></div>
                                            <button className='contact-form-btn'>
                                                Send
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div> */}
                        </div>
                    </div>
                    {/* <div id="particles-js"></div> */}
                    {/* <canvas id='canvas1' ref={this.canvasRef}></canvas> */}
                </section>
            </>
        );
    }
}

const mapStateToProps = (state, passed) => {
    return {
        data: state.content.contactMe[passed.id]
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactMe);

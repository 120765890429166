import React, { Component } from 'react';
import { connect } from 'react-redux';
import './index.scss';
// import 'react-typed/dist/animatedCursor.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import 'aos/dist/aos.js'; // You can also use <link> for styles

// import Col from 'react-bootstrap';
// import Row from 'react-bootstrap';

class BlogPosts extends Component {
    render() {
        const { title, blogs } = this.props.data;
        AOS.init();
        // <>{content.map((section, i) => this.handleContent(section, i))}</>
        return (
            <>
                <section
                    className='blogPosts module-gray module'
                    title={title}
                    id={title}
                    data-aos='fade-up'
                    data-aos-offset='-200'
                    data-aos-delay='50'
                    data-aos-duration='1000'
                    data-aos-easing='ease-in-out'
                    data-aos-mirror='true'
                    data-aos-once='false'
                    data-aos-anchor-placement='top-center'>
                    <div className='container-blogPosts'>
                        <Container>
                            <div className='module-header'>
                                <h2 className='module-title'>
                                    Pranav's Blog <span>A Fresh Inquiry</span>
                                </h2>
                                <div className='module-line'></div>
                            </div>
                            <Row>
                                {blogs.map((blog, i) => (
                                    <Col
                                        sm='4'
                                        className='blogPosts'
                                        data-aos='fade-up'
                                        data-aos-offset='-200'
                                        data-aos-delay='50'
                                        data-aos-duration='1000'
                                        data-aos-easing='ease-in-out'
                                        data-aos-mirror='true'
                                        data-aos-once='false'
                                        data-aos-anchor-placement='top-center'
                                        key={i}>
                                        <a
                                            href={blog.link}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            className='blogList'>
                                            <div className='blogList-img'>
                                                <img
                                                    src={require(`${blog.img.src}`)}
                                                    alt={blog.img.alt}
                                                />

                                                <span>{blog.tag}</span>
                                            </div>
                                            <div className='blogList-desc'>
                                                <h3>{blog.title}</h3>
                                                <div>{blog.desc}</div>
                                            </div>
                                        </a>
                                    </Col>
                                ))}
                                <br className='clearIt' />
                            </Row>
                        </Container>
                    </div>
                    {/* <div id="particles-js"></div> */}
                    {/* <canvas id='canvas1' ref={this.canvasRef}></canvas> */}
                </section>
            </>
        );
    }
}

const mapStateToProps = (state, passed) => {
    return {
        data: state.content.blogPosts[passed.id]
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(BlogPosts);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import './index.scss';
import { ReactTyped } from "react-typed";
// import 'react-typed/dist/animatedCursor.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import 'aos/dist/aos.js'; // You can also use <link> for styles

// import Col from 'react-bootstrap';
// import Row from 'react-bootstrap';

class AboutMe extends Component {
    render() {
        const { title, img, titlebk, info1, info2 } = this.props.data;
        let divStyle = {
            // background: 'url(' + require(`${img}`) + ') scroll center no-repeat',
            // backgroundSize: 'cover',
            // height: '100%'
        };
        AOS.init();
        // <>{content.map((section, i) => this.handleContent(section, i))}</>
        return (
            <>
                <section
                    className='aboutMe module'
                    title={title}
                    id={title}
                    data-aos='fade-up'
                    data-aos-offset='-200'
                    data-aos-delay='50'
                    data-aos-duration='1000'
                    data-aos-easing='ease-in-out'
                    data-aos-mirror='true'
                    data-aos-once='false'
                    data-aos-anchor-placement='top-center'>
                    <div className='container-aboutMe'>
                        <Container>
                            <Row>
                                <Col
                                    xs='12'
                                    md='6'
                                    className='side-image '
                                    style={divStyle}>
                                    <Image
                                        src={require(`${img}`)}
                                        fluid
                                        rounded
                                    />
                                </Col>

                                <Col
                                    xs={12}
                                    md={6}
                                    md-offset={6}
                                    className='side-image-text'
                                    id='right'>
                                    <Row>
                                        <Col sm='12'>
                                            <div className='module-header'>
                                                <h1 className='module-title h2'>
                                                    {title}
                                                    <span>{titlebk}</span>
                                                </h1>
                                                <div className='module-line'></div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div className='iconboxleft'>
                                            <div className='iconboxleft-icon'>
                                                <FontAwesomeIcon icon='microchip'></FontAwesomeIcon>
                                            </div>
                                            <div className='iconboxleft-text'>
                                                <h3 className='iconboxleft-title'>
                                                    {info1.title}
                                                </h3>
                                                <div className='iconboxleft-desc'>
                                                    {info1.description}
                                                </div>
                                            </div>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className='iconboxleft'>
                                            <div className='iconboxleft-icon'>
                                                <FontAwesomeIcon icon='mug-hot'></FontAwesomeIcon>
                                            </div>
                                            <div className='iconboxleft-text'>
                                                <h3 className='iconboxleft-title'>
                                                    {info2.title}
                                                </h3>
                                                <div className='iconboxleft-desc'>
                                                    {info2.description}
                                                </div>
                                            </div>
                                        </div>
                                    </Row>
                                </Col>
                                <br className='clearIt' />
                            </Row>
                        </Container>
                    </div>
                    {/* <div id="particles-js"></div> */}
                    {/* <canvas id='canvas1' ref={this.canvasRef}></canvas> */}
                </section>
            </>
        );
    }
}

const mapStateToProps = (state, passed) => {
    return {
        data: state.content.aboutMe[passed.id]
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AboutMe);

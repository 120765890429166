// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.linkClub {
  color: #56bbca; }

.linkClub:hover {
  text-decoration: none;
  color: #116466; }
`, "",{"version":3,"sources":["webpack://./src/store/components/clubs/index.scss"],"names":[],"mappings":"AAAA;EACI,cACJ,EAAA;;AAEA;EACI,qBAAqB;EACrB,cAAc,EAAA","sourcesContent":[".linkClub {\n    color: #56bbca\n}\n\n.linkClub:hover {\n    text-decoration: none;\n    color: #116466;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

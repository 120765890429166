import React, { Component } from 'react';
import { connect } from 'react-redux';
import './index.scss';
import { ReactTyped, Typed } from "react-typed";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleDown } from '@fortawesome/free-solid-svg-icons';
import { Link} from 'react-scroll';

class Home extends Component {

    render() {
        const { title, typedString, next } = this.props.data;
        // const [typed,setTyped] = React.useState<Typed| undefined>();

        // <>{content.map((section, i) => this.handleContent(section, i))}</>
        return (
            <>
                <section className='homeElement' title={title} id={title}>
                    <div className='containerHome'>
                        {/* <ParticleComponent /> */}
                        {/* <div>
                            <Particles init={particlesInit} options={particlesOptions} />
                        </div> */}
                        {/* <div className='modal'></div> */}
                        <h3 id='intro'>My name is Pranav Goel and</h3>
                        <ReactTyped
                            className='typedClass'
                            strings={typedString}
                            typeSpeed={40}
                            backSpeed={50}
                            loop></ReactTyped>
                        {/* <Link
                            activeClass='click'
                            to={next}
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={1000}>
                        <FontAwesomeIcon
                            className='downArrow'
                            icon={faArrowCircleDown}></FontAwesomeIcon>
                            </Link> */}
                    </div>

                    {/* <div id="particles-js"></div> */}
                    {/* <canvas id='canvas1' ref={this.canvasRef}></canvas> */}
                </section>
            </>
        );
    }
}

const mapStateToProps = (state, passed) => {
    return {
        data: state.content.home[passed.id]
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);

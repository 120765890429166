// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.company {
  position: relative;
  margin: 25px 0px auto auto !important;
  margin-left: 70px !important; }
  .company h2 {
    text-align: left;
    margin-right: 20px; }

.work {
  text-align: left; }
  .work p {
    margin-left: 69px !important; }

.closer {
  margin: 25px auto auto 0;
  padding: 0; }

.overrideRow {
  margin-right: 0px;
  padding-left: 82px; }

@media (max-width: 440px) {
  .overrideRow {
    margin-right: 0px;
    padding-left: 32px; } }

.rowQualifiers {
  margin-top: -20px; }

#companyDesc {
  text-align: left;
  margin-right: 20px; }

.contentFormat {
  font: 400 12px/1.8 'Poppins', sans-serif; }

.companyBox {
  background: #fff none repeat scroll 0 0;
  padding: 50px 20px;
  position: relative;
  max-width: 3400px;
  margin: 30px;
  box-shadow: -6px 10px 40px rgba(0, 0, 0, 0.06); }

.headPadding {
  text-align: left !important; }

.aboutPadding {
  padding-right: 30px; }
`, "",{"version":3,"sources":["webpack://./src/store/components/workExperience/index.scss"],"names":[],"mappings":"AAAA;EACI,kBAAkB;EAClB,qCAAqC;EACrC,4BAA2B,EAAA;EAH/B;IAMQ,gBAAgB;IAChB,kBAAkB,EAAA;;AAI1B;EACI,gBAAgB,EAAA;EADpB;IAGQ,4BAA2B,EAAA;;AAInC;EACI,wBAAwB;EACxB,UAAU,EAAA;;AAGd;EACI,iBAAiB;EACjB,kBAAiB,EAAA;;AAGrB;EACI;IACI,iBAAiB;IACjB,kBAAiB,EAAA,EACpB;;AAEL;EACI,iBAAiB,EAAA;;AAGrB;EACI,gBAAgB;EAChB,kBAAkB,EAAA;;AAGtB;EACI,wCAAwC,EAAA;;AAG5C;EACI,uCAAuC;EACvC,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB;EACjB,YAAY;EACZ,8CAA8C,EAAA;;AAGlD;EACI,2BAA0B,EAAA;;AAG9B;EACI,mBAAmB,EAAA","sourcesContent":[".company {\n    position: relative;\n    margin: 25px 0px auto auto !important;\n    margin-left: 70px!important;\n\n    h2 {\n        text-align: left;\n        margin-right: 20px;\n    }\n}\n\n.work {\n    text-align: left;\n    p {\n        margin-left: 69px!important;\n    }\n}\n\n.closer {\n    margin: 25px auto auto 0;\n    padding: 0;\n}\n\n.overrideRow {\n    margin-right: 0px;\n    padding-left:82px;\n}\n\n@media (max-width: 440px) {\n    .overrideRow {\n        margin-right: 0px;\n        padding-left:32px;\n    }}\n\n.rowQualifiers {\n    margin-top: -20px;\n}\n\n#companyDesc {\n    text-align: left;\n    margin-right: 20px;\n}\n\n.contentFormat {\n    font: 400 12px/1.8 'Poppins', sans-serif;\n}\n\n.companyBox {\n    background: #fff none repeat scroll 0 0;\n    padding: 50px 20px;\n    position: relative;\n    max-width: 3400px;\n    margin: 30px;\n    box-shadow: -6px 10px 40px rgba(0, 0, 0, 0.06);\n}\n\n.headPadding {\n    text-align: left!important;\n}\n\n.aboutPadding {\n    padding-right: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { Component } from 'react';
import './App.scss';
import Content from './store/components/content';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
    faDesktop,
    faCoffee,
    faMicrochip,
    faMugHot,
    faLanguage,
    faMemory,
    faGears,
    faProjectDiagram,
    faChartLine,
    faCode,
    faShieldAlt,
    faPlus,
    faMinus
} from '@fortawesome/free-solid-svg-icons';
import {
    faTwitterSquare,
    faFacebookSquare,
    faLinkedin,
    faInstagram,
    faGithubSquare
} from '@fortawesome/free-brands-svg-icons';

library.add(
    fab,
    faInstagram,
    faTwitterSquare,
    faFacebookSquare,
    faLinkedin,
    faGithubSquare,
    faDesktop,
    faCoffee,
    faMicrochip,
    faMugHot,
    faLanguage,
    faMemory,
    faGears,
    faProjectDiagram,
    faChartLine,
    faCode,
    faShieldAlt,
    faPlus,
    faMinus
);
class App extends Component {
    render() {
        return (
            <div id='App'>
                <Content />
            </div>
        );
    }
}

export default App;

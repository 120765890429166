import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-scroll';
import './index.scss';

class Navbar extends Component {
    render() {
        const { heading, nav } = this.props.data;
        const img = './images/previewImagesmall.png'
        // open (or close) submenu items in mobile view menu.
        // close all the other open submenu items.

        return (
            <nav className='navbar'>
                <img src={require(`${img}`)} alt="Pranav Goel logo" id="heading2"/>
                <h2 className='h6' id="heading">{heading}</h2>
                {/* <!-- end header__nav --> */}
                <ul className='navbar_content'>
                    <div className='element_container'>
                        {nav.map((item, i) => (
                            <Link
                                activeClass='active'
                                to={item.title}
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                                key={i}>
                                <li>
                                    <p>{item.title}</p>
                                </li>
                            </Link>
                        ))}
                    </div>
                </ul>
                {/* <!-- end header__nav-wrap --> */}
            </nav>
        );
    }
}

const mapStateToProps = (state, passed) => {
    return {
        data: state.content.navbar[passed.id]
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);

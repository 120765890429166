import React from 'react';
import HomeHero from '../store/components/homeHero';
import Navbar from '../store/components/navbar';
import Home from '../store/components/home';
import AboutMe from '../store/components/aboutMe';
import Skills from '../store/components/skills';
import Software from '../store/components/software';
import WorkExperience from '../store/components/workExperience';
import Clubs from '../store/components/clubs';
import BlogPosts from '../store/components/blogPosts';
import ContactMe from '../store/components/contactMe';

// import TermsMarketplacePage from 'component/plainHTMLpages/terms-individual.js';

const mapObjectToComponent = (instance, i) => {
    const { component, id } = instance;

    switch (component) {
        // for Homepage
        case 'homeHero':
            return <HomeHero key={i} id={id} />;
        case 'navbar':
            return <Navbar key={i} id={id} />;
        case 'home':
            return <Home key={i} id={id} />;
        case 'aboutMe':
            return <AboutMe key={i} id={id} />;
        case 'skills':
            return <Skills key={i} id={id} />;
        case 'software':
            return <Software key={i} id={id} />;
        case 'workExperience':
            return <WorkExperience key={i} id={id} />;
        case 'clubs':
            return <Clubs key={i} id={id} />;
        case 'blogPosts':
            return <BlogPosts key={i} id={id} />;
        case 'contactMe':
            return <ContactMe key={i} id={id} />;
        default:
            return <div key={i} id='incorrectInput' />;
    }
};
export default mapObjectToComponent;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import './index.scss';
// import 'react-typed/dist/animatedCursor.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// import Col from 'react-bootstrap';
// import Row from 'react-bootstrap';

class Skills extends Component {
    render() {
        const { title, skills } = this.props.data;
        AOS.init();
        // <>{content.map((section, i) => this.handleContent(section, i))}</>
        return (
            <>
                <section
                    className='skills module-gray module'
                    title={title}
                    id={title}
                    data-aos='fade-up'
                    data-aos-offset='-200'
                    data-aos-delay='50'
                    data-aos-duration='1000'
                    data-aos-easing='ease-in-out'
                    data-aos-mirror='true'
                    data-aos-once='false'
                    data-aos-anchor-placement='top-center'>
                    <div className='container-skills'>
                        <Container>
                            <div className='module-header'>
                                <h2 className='module-title'>
                                    Pranav's Skills <span>Skills</span>
                                </h2>
                                <div className='module-line'></div>
                            </div>
                            <Row>
                                {skills.map((skill, i) => (
                                    <Col
                                        sm='4'
                                        className='skills'
                                        data-aos='fade-up'
                                        data-aos-offset='-200'
                                        data-aos-delay='50'
                                        data-aos-duration='1000'
                                        data-aos-easing='ease-in-out'
                                        data-aos-mirror='true'
                                        data-aos-once='false'
                                        data-aos-anchor-placement='top-center'
                                        key={i}>
                                        <div className='iconbox'>
                                            <div className='iconbox-icon'>
                                                <FontAwesomeIcon
                                                    icon={
                                                        skill.icon
                                                    }></FontAwesomeIcon>
                                            </div>
                                            <div className='iconbox-text'>
                                                <h3 className='iconbox-title'>
                                                    {skill.title}
                                                </h3>
                                                <div className='iconbox-desc'>
                                                    {skill.desc}
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                ))}
                                <br className='clearIt' />
                            </Row>
                        </Container>
                    </div>
                    {/* <div id="particles-js"></div> */}
                    {/* <canvas id='canvas1' ref={this.canvasRef}></canvas> */}
                </section>
            </>
        );
    }
}

const mapStateToProps = (state, passed) => {
    return {
        data: state.content.skills[passed.id]
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Skills);

import React, { Component } from 'react';
import { connect } from 'react-redux';

// import Navbar from 'components/navbar';
// import Navbar from 'components/navbar';

//import actions from 'store/actions';
// import './homeHero.scss';

class HomeHero extends Component {
    render() {
        const { title, body } = this.props.data;
        return (
            <div className='home-hero'>
                <h1>{title}</h1>
                <h5>{body}</h5>
                
            </div>
        );
    }
}

const mapStateToProps = (state, passed) => {
    return {
        data: state.content.dynamicPage.homeHero[passed.id]
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HomeHero);

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon {
  color: #000000; }

.seperate {
  padding: 0px 20px;
  position: relative;
  text-align: center;
  max-width: 340px;
  margin: 30px auto; }

.progName {
  padding-top: 15px; }
`, "",{"version":3,"sources":["webpack://./src/store/components/software/index.scss"],"names":[],"mappings":"AAAA;EACI,cAAc,EAAA;;AAGlB;EACQ,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAA;;AAGzB;EACI,iBAAiB,EAAA","sourcesContent":[".icon {\n    color: #000000;\n}\n\n.seperate {\n        padding: 0px 20px;\n        position: relative;\n        text-align: center;\n        max-width: 340px;\n        margin: 30px auto;\n}\n\n.progName {\n    padding-top: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// create action in action/index.js

import * as actionTypes from '../actionTypes';

// switch the tab on homepage between API, CSV export and accounting integration
export function switchComponent(id, data) {
    return {
        type: actionTypes.SWITCH_COMPONENT,
        payload: {
            id: id,
            data: data
        }
    };
}

export function setPostName(name) {
    return {
        type: actionTypes.SET_POST_TITLE,
        payload: name
    }
}

// export function setCBCustomization(data) {
//     return {
//         type: actionTypes.SET_CB_CUSTOMIZATION,
//         payload: data
//     };
// }

